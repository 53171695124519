import React, { useState, useEffect } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import tw from 'twin.macro';
import styled from 'styled-components';
import { Section, Container } from '../../../styles/common/Layout';
import { faInstagramSquare, faFacebookSquare, faGithubSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useScrollData } from 'scroll-data-hook';
import { useTransition } from 'react-spring';
import ScrollToTop from '../ui/scrollToTop';

const PageHeader = tw.header`
  w-full
`;

const Topheader = tw.div`
  w-full
  px-4
  mt-8
  mb-8
  md:p-0
  md:mt-16
  md:mb-16
`;

const BottomHeader = tw.div`
  flex flex-row justify-between
`;

const AIg = tw.a`
  opacity-50
  hover:opacity-100
  hover:text-instagram
`;

const AFb = tw.a`
  opacity-50
  hover:opacity-100
  hover:text-facebook
`;

const AGh = tw.a`
  opacity-50
  hover:opacity-100
  hover:text-github
`;

const ALi = tw.a`
  opacity-50
  hover:opacity-100
  hover:text-linkedin
`;

const DesignHr = styled.hr`
  ${tw`mr-4`};
  background: #d4d4d4;
  height: 0.05rem;
  border: none;
  max-width: 15rem;
  width: 15rem;
  @media (max-width: 414px) {
    width:5rem;
  }

`

export default function Header() {
  const { position } = useScrollData();

  // State to show or hide scroll to top component, gets trigged based on the scroll link component
  const [showScrollTop, setShowScrollTop] = useState(false);
  // Animation
  const ScrollTopTransition = useTransition(showScrollTop, null, {
    from: { opacity: 0 },
    enter: { opacity: .5 },
    leave: { opacity: 0 },
  });
  useEffect(() => {
    if (position.y > 90) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  }, [position]);

  const data = useStaticQuery(graphql`query HeaderQuery {
    logo: file(relativePath: {eq: "images/MAM_Logo_1c_grey_trans.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
  `);
  const headerLogo = data.logo.childImageSharp.gatsbyImageData;
  return <>
    <PageHeader>
      <Section Color="White" id="section__header" NoPadding>
        <Topheader>
          <Container>
            <div className="flex flex-row justify-between items-center">
              <Link to="/">
                <GatsbyImage
                  image={headerLogo}
                  style={{ width: "50px" }}
                  alt="Marek Maras Header Logo" />
              </Link>

              <div className="flex items-center">
                <DesignHr />
                <div className="px-2 flex space-x-4">
                  <AIg title="Marek Maras Instagram" href="https://www.instagram.com/marek123/" rel="noreferrer">
                    <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
                  </AIg>
                  <AFb title="Marek Maras Facebook" href="https://www.facebook.com/marek123/" rel="noreferrer">
                    <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
                  </AFb>
                  <AGh title="Marek Maras Github" href="https://github.com/MarekMaras" rel="noreferrer">
                    <FontAwesomeIcon icon={faGithubSquare} size="2x" />
                  </AGh>
                  <ALi title="Marek Maras LinkedIn" href="https://www.linkedin.com/in/marek-maras-662829200/" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                  </ALi>

                </div>
              </div>
            </div>
          </Container>
        </Topheader>
      </Section>
    </PageHeader>
    {ScrollTopTransition.map(
      ({ item, key, props }) =>
        item && <ScrollToTop style={props} key={key} />
    )}
  </>;
}
