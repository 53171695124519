import React from 'react';
import { useStaticQuery, Link } from 'gatsby'
import PropTypes from 'prop-types';
import tw, { GlobalStyles } from 'twin.macro'
import Footer from './footer';
import Header from './header';
import i18n from '../../../config/i18n';

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale }, location }) => {
  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <Header />
      {children}
      <Footer locale={locale} />
    </LocaleContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export { LocaleContext, Layout }