import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import tw from 'twin.macro';
import styled from 'styled-components';
import LocalizedLink from '../../LocalizedLink';
import linkResolver from '../../../utils/linkResolver'
import FooterNavItem from './FooterNavItem';
import { Container } from '../../../styles/common/Layout';

const FooterNav = tw.nav`
  flex
  flex-col
  items-center
  space-x-4
  sm:flex-row
  sm:flex-nowrap
`;

const A = styled(LocalizedLink)`
  ${tw`
    transition-all
    flex
    justify-center
    h-12
    md:h-auto
  `}

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background-color: black;
    position: relative;
    bottom: 0px;
    transition: width .5s;
  }
  &:hover::after {
    width: 105%;
  }
`;

const InnerWrapper = tw.div`
  container
  mx-auto
  flex
  justify-between
  pb-24
  pt-16
  flex-col
  md:flex-row
`;

const FooterStyle = tw.footer`
  w-full
  h-auto
  bg-lightgray
`;

export default function Footer({ locale }) {
  const data = useStaticQuery(graphql`query FooterQuery {
    file(relativePath: {eq: "images/MAM_Logo_1c_grey_trans.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
  `)
  const footerLogo = data.file.childImageSharp.gatsbyImageData;

  const footerMenu = [
    { to: '/blog', hrefTitle: 'Blog', linkName: 'blog' },
    { to: '/now', hrefTitle: 'Now', linkName: '/now' },
    { to: '/uses', title: 'Uses', linkName: '/uses' },
    { to: '/kontakt', title: 'Kontakt', linkName: 'kontakt' },
    { to: '/datenschutz', title: 'Datenschutz', linkName: 'datenschutz' },
    { to: '/impressum', title: 'Impressum', linkName: 'impressum' },
  ]

  return <>
    <FooterStyle>
      <Container>
        <InnerWrapper>
          <div className="flex justify-center">
            <Link to="/">
              <GatsbyImage
                image={footerLogo}
                style={{ width: "50px" }}
                alt="Marek Maras Footer Logo" />
            </Link>
          </div>
          <FooterNav>
            {footerMenu.map((item, index) => (
              <FooterNavItem
                key={index}
                locale={locale}
                to={item.to}
                title={item.hrefTitle}
                rel="noreferrer">{item.linkName}</FooterNavItem>
            )

            )}
          </FooterNav >
        </InnerWrapper >
      </Container>
    </FooterStyle >
  </>;
}