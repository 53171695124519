import "tailwindcss/base.css"
import "tailwindcss/components.css"
import "tailwindcss/utilities.css"
import "fontsource-muli"

import "./src/styles/globals.css"
import 'swiper/css/swiper.min.css'

import React from 'react'
import LocaleWrap from './wrapPageElement'

export const wrapPageElement = LocaleWrap