import React from 'react'
import { LocaleContext } from '../layout'
import locales from '../../../../config/i18n'
import LocalizedLink from '../../LocalizedLink';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'gatsby';

const A = styled(Link)`
  ${tw`
    transition-all
    flex
    justify-center
    h-12
    md:h-auto
  `}
  span {
    &:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background-color: rgba(75,85,99);
    position: relative;
    bottom: 0px;
    transition: width .6s;
  }
  &:hover::after {
    width: 105%;
  }
  }

`;

export default function FooterNavItem({ locale, children, to, ...props }) {
  let page = to.split("/")[1].replace('/', '')
  const path = locales[locale].default ? `${to}` : `/${locales[locale].path}/${page}`

  return (
    <>
      <A {...props} to={path}><span>{children}</span></A>
    </>
  )
}
