module.exports = {
  'contact': {
    headline: 'Contact',
    address:
      'Marek Maras <br />' +
      'Marek Maras - Frontend Webentwicklung<br />' +
      'Rathausplatz 6<br />' +
      '45525 Hattingen, Germany<br />',
    contactDetails: 'Phone: +49 (0) 176 10 27 15 61<br />' +
      'E-Mail: mail@marek-maras.de',
    covidText: "The Corona Pandemic is here, it's real and has a huge impact on body and soul. <br />" +
      "I would love to meet with you, drink a coffee and discuss your future projects or ideas," +
      "but unfortunately this isn't possible right now.<br />" +
      '  <strong>But I am still here for you!</strong>',
    covidText2: 'Currently I communicate with',
    covidTextBye: 'Stay healthy!',
    covidTextTagEnd: '... any many more!',
  },
  'contactForm': {
    headline: 'Contact Form',
    name: 'Your Name',
    namePlaceholder: 'Your Name',
    mail: 'Your E-Mail',
    mailPlaceholder: 'your@email.com',
    phone: 'Your Phone',
    phonePlaceholder: '0123 / 456789',
    website: 'Your Website',
    websitePlaceholder: 'www.your-website.com',
    message: 'Your Message',
    submitButton: 'Send Message',
    dataStorageHint: '<strong>About your data privacy & storage:</strong><br /> We will not send you any kind of advertisement or spam mail. You can revoke your consent to data storage at any time by sending an e-mail to mail@marek-maras.de. This form is only used to contact you.',
    successfullySubmittedMessage: 'Horray! Message successfully submitted!',
    terms: 'Ich habe die <ALink to="/datenschutz"><span>Datenschutzerklärung</span></ALink> zur Kenntnis genommen und stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden.',
    errors: {
      name: 'Please enter your name!',
      mail: 'Please enter an e-mail',
      message: 'Please write a message! Cannot help you, without knowing what the problem is!',
      terms: 'Privacy and data-safety is important! Please accept my data/privacy policy!'
    }
  }
}