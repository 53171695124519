import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { animateScroll as scroll } from 'react-scroll';
import { animated } from 'react-spring';
import tw from 'twin.macro';

const StyledWrapper = styled(animated.div)`
  ${tw`
    fixed
    bg-gray-500
    h-12 w-12
    flex
    items-center
    justify-center
    mr-4
    mb-8
  `}
  z-index: 20;
  bottom: 0;
  right: 0;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    transform: translateY(-3px);
  }
  &:active {
    transform: translateY(1px);
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 1.2rem;
`;

const ScrollToTop = ({ style }) => (
  <StyledWrapper style={style} onClick={() => scroll.scrollToTop()}>
    <StyledIcon icon={faChevronUp} />
  </StyledWrapper>
);

export default ScrollToTop;
