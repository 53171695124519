module.exports = {
  'contact': {
    headline: 'Kontakt',
    address:
      'Marek Maras <br />' +
      'Marek Maras - Frontend Webentwicklung<br />' +
      'Rathausplatz 6<br />' +
      '45525 Hattingen, Deutschland<br />',
    contactDetails: 'Telefon: +49 (0) 176 10 27 15 61<br />' +
      'E-Mail: mail@marek-maras.de',
    covidText:
      'Corona hat uns momentan fest im Griff und belastet Körper und Seele.<br />' +
      'Ich würde gerne mit dir persönlich deine Webseite durchklicken, das Angebot durchgehen oder einen ' +
      'Kaffee trinken, doch leider ist das derzeit nicht möglich.<br />' +
      '  <strong>Ich bin dennoch weiterhin für euch da!</strong>',
    covidText2: 'Ich benutze derzeit folgende digitale Kommunikationsmittel',
    covidTextBye: 'Bleibt gesund!',
    covidTextTagEnd: '... und viele mehr!',
  },
  'contactForm': {
    headline: 'Kontaktformular',
    name: 'Dein Name',
    namePlaceholder: 'Dein Name',
    mail: 'Deine E-Mail',
    mailPlaceholder: 'deine@email.de',
    phone: 'Deine Telefonnummer',
    phonePlaceholder: '0123 / 456789',
    website: 'Deine Webseite',
    websitePlaceholder: 'www.deine-webseite.de',
    message: 'Deine Nachricht oder Anfrage',
    submitButton: 'Absenden',
    dataStorageHint: '<strong>Hinweis zur Datenspeicherung:</strong><br /> Wir senden Dir keine Werbung zu. Du kannst Deine Einwilligung zur Datenspeicherung jederzeit per E-Mail an mail@marek-maras.de widerrufen. Dieses Formular dient ausschließlich der Kontaktaufnahme.',
    successfullySubmittedMessage: 'Horray! Nachricht erfolgreich abgeschickt!',
    terms: 'Ich habe die <ALink to="/datenschutz"><span>Datenschutzerklärung</span></ALink> zur Kenntnis genommen und stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden.',
    errors: {
      name: 'Bitte gebe deinen Namen ein',
      mail: 'Bitte gebe eine korrekte E-Mail an',
      message: 'Bitte schreibe auch eine Nachricht! Ohne kann ich dir nicht helfen!',
      terms: 'Datenschutz ist wichtig! Bitte akzeptiere die Datenschutzerklärung.'
    }
  }
}