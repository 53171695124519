module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Marek Maras • Webentwicklung aus'm Ruhrpott", // Navigation and Site Title
  titleAlt: "Marek Maras • Webentwicklung aus'm Ruhrpott", // Title for JSONLD
  titleTemplate: "%s • Marek Maras • Webentwicklung aus'm Ruhrpott",
  description: "Ich bin Marek, Webentwickler und Tourmanager aus Hattingen, aus'm Ruhrgebiet.",
  headline: 'Developing great websites and apps', // Headline for schema.org JSONLD
  url: 'https://www.marek-maras.de', // Domain of your site. No trailing slash!
  logo: '/images/logo_small_grey.png', // Used for SEO
  ogLanguage: 'de_DE', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/assets/images/MAM_logo_small_grey.png', // Used for manifest favicon generation
  shortName: 'marekmaras', // shortname for manifest. MUST be shorter than 12 characters
  author: 'MarekMaras', // Author for schemaORGJSONLD
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',

  twitter: 'MarekMaras', // Twitter Username
  facebook: '', // Facebook Site Name
  googleAnalyticsID: 'UA-XXXXXX-X',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}