import styled from 'styled-components';
import tw from 'twin.macro'

export const Container = tw.div`
  container
  xl:max-w-screen-xl
  mx-auto
`;

export const Section = styled.section`
  ${tw`w-full pt-12 pb-12 md:pt-32 md:pb-32 px-5`};
  --tw-bg-opacity: 1;
  background-color: ${props => props.Color === 'White' ? '#fff' : 'rgba(249, 250, 251, var(--tw-bg-opacity));'};
  ${props => props.NoPadding && `
    padding: 0 !important;
  `}
  ${(props) => props.SmallPadding && tw`md:pt-16 md:pb-16`}
`;